import React from 'react';

const HeaderSecondary = ({ children, className }) => {

    return (
        <h2 className={`text-4xl lg:text-5xl font-bold font-mono text-text tracking-tight${` `}${className}`}>
            {children}
        </h2>
    )
}

export default HeaderSecondary