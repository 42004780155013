import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Logo from '../components/graphics/logo';
import HeaderSecondary from '../components/blocks/HeaderSecondary';

const IndexPage = ({ data }) => (
  <Layout>
    <HelmetDatoCms
      favicon={data.datoCmsSite.faviconMetaTags}
      seo={data.datoCmsHome.seoMetaTags}>
    </HelmetDatoCms>

    <Link
      to="/"
      title="Home">
      <Logo className={"hidden lg:block w-72 -ml-sm"} />
    </Link>

    {data.allDatoCmsPost.edges.map(({ node: item }) => (
      <div className="space-y-xs drop-shadow-md pb-lg" key={item.created}>
        <div className="space-y-0">
          <span className="text-white bg-text px-xxs opacity-50 font-mono text-sm">
            {item.created}
          </span>
          <HeaderSecondary>{item.title}</HeaderSecondary>
        </div>
        <div className="markdown font-sans break-words text-lg text-text" dangerouslySetInnerHTML={{
          __html: item.contentNode.childMarkdownRemark.html,
        }}></div>
        {item.link
          ?
          <div className="iframe-container">
            <iframe
              className="iframe"
              style={{ border: 'none' }}
              width="560"
              height="315"
              src={item.link}
              frameBorder="0"
              allowFullScreen
              title="yt-concert"
            />
          </div>
          :
          <div className="w-full">
            <Img
              className='w-full h-full'
              fluid={item.cover.fluid}>
            </Img>
          </div>
        }
      </div>
    ))}
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    datoCmsSite {
      globalSeo {
        siteName
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    allDatoCmsPost(sort: { fields: [created], order: DESC }) {
      edges {
        node {
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          created
          link
          cover {
            fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
              }
          }
        }
      }
    }
  }
`
